<template>
	<v-app>
		<template v-if="isSubmitted">
			<v-col v-if="isSubmitted" md="12" class="px-10 py-0">
				<div class="quotation-approved my-20">
					<div class="card">
						<div class="crossBtn" v-on:click="closeTab">
							<i class="crossIcon">𐤕</i>
						</div>
						<div
							style="
								border-radius: 200px;
								height: 200px;
								width: 200px;
								background: #f8faf5;
								margin: 0 auto;
							"
						>
							<i class="checkmark">✓</i>
						</div>
						<h1>Success</h1>
						<p>
							We received your approval against Retired #
							{{ orderData.barcode }}<br />
							we'll be in touch shortly!<br />
							Thank You!
						</p>
					</div>
				</div>
			</v-col>
		</template>
		<div v-if="!isSubmitted" class="checkout-aprove-container px-10 py-8">
			<v-row class="border-bottom">
				<v-col cols="12">
					<img :src="$assetURL('media/header_img.png')" alt="Pico-Guards-logo" />
				</v-col>
			</v-row>

			<div class="mt-4">
				<table class="w-100 font-size-14">
					<tr>
						<td width="50%" rowspan="2">
							<label class="font-size-14"
								><b>Employee Name : </b>
								<span class="font-size-14 blue--text fw-500" v-if="memberData">{{
									memberData.display_name
								}}</span></label
							>
						</td>
						<td>
							<label class="font-size-14">
								<b>Asset Retired No : </b>
								<span class="font-size-14 blue--text fw-500">
									{{ orderData.barcode }}
								</span></label
							>
						</td>
					</tr>
					<tr>
						<td>
							<label class="font-size-14">
								Retired Date :
								<span class="font-size-14 blue--text fw-500"> {{ currentDate }}</span>
							</label>
						</td>
					</tr>
					<tr>
						<td width="50%">
							<label class="font-size-14"
								><b>Employee Id : </b>
								<span class="font-size-14 blue--text fw-500" v-if="memberData">{{
									memberData.id_number
								}}</span></label
							>
						</td>
						<td>
							<label class="font-size-14">
								Approved By :
								<span class="font-size-14 blue--text fw-500" v-if="orderData && orderData.added_by">
									{{ orderData.added_by.display_name }}
								</span>
							</label>
						</td>
					</tr>
				</table>
			</div>

			<div class="mt-4">
				<table class="w-100 font-size-14">
					<tr>
						<td colspan="5">
							Anytime an asset needs to be disposed, Asset Retired Form shall be completed. This form does
							not grant approval to disposed of equipment, it only serves to update the inventory database.
							A disposal record shall be filed together with this form.
						</td>
					</tr>
					<tr>
						<th width="40" class="text-center">S.N.</th>
						<th>Item</th>
						<th width="120" class="text-center">Asset Code</th>
						<th width="60" class="text-center">Qty</th>
						<th>Reason</th>
					</tr>
					<tr v-for="(row, index) in retired" :key="index">
						<th widtd="40" class="text-center">{{ index + 1 }}</th>
						<td>
							<template v-if="row && row.asset">{{ row.asset.name }}</template>
							<template v-if="row && row.asset_stock">{{ row.asset_stock.name }}</template>
							<template v-if="row && row.inventory">{{ row.inventory.name }}</template>
						</td>
						<td widtd="100" class="text-center">
							<template v-if="row && row.asset">{{ row.asset.barcode }}</template>
							<template v-if="row && row.asset_stock">{{ row.asset_stock.barcode }}</template>
							<template v-if="row && row.inventory">{{ row.inventory.barcode }}</template>
						</td>
						<td widtd="60" class="text-center">{{ quantity(row) }}</td>
						<td>
							<template v-if="row && row.retired">{{ row.retired.description }}</template>
						</td>
					</tr>
				</table>
			</div>
			<div class="mt-4">
				<table class="w-100 font-size-14">
					<tr>
						<td width="50%" height="100" class="border-bottom-color-none" style="position: relative">
							<div class="signature-pad" id="signature-pad">
								<div ref="signature_div1" class="custom-border-grey-dashed mt-2" style="width: 100%">
									<canvas ref="cc_signature1"></canvas>
								</div>
							</div>
							<v-btn
								small
								v-on:click="clear_signature('approver_signature')"
								text
								outlined
								style="position: absolute; bottom: 10px; right: 10px"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</td>
						<td width="50%" class="border-bottom-color-none" style="position: relative">
							<div class="signature-pad" id="signature-pad">
								<div ref="signature_div" class="custom-border-grey-dashed mt-2" style="width: 100%">
									<canvas ref="cc_signature"></canvas>
								</div>
							</div>
							<v-btn
								v-if="!orderData.admin_signature"
								small
								v-on:click="clear_signature('requester_signature')"
								text
								outlined
								style="position: absolute; bottom: 10px; right: 10px"
								><v-icon>mdi-close</v-icon></v-btn
							>
						</td>
					</tr>
					<tr>
						<td width="50%"><b>In-charge Officer</b></td>
						<td width="50%"><b>Approved by</b></td>
					</tr>

					<tr>
						<td width="50%" colspan="2" class="text-right">
							<v-btn
								tile
								depressed
								:loading="pageLoading"
								v-on:click="closeTab"
								:disabled="pageLoading"
								class="mr-4"
							>
								Cancel
							</v-btn>
							<v-btn
								tile
								depressed
								v-on:click="approveRetired"
								:loading="pageLoading"
								:disabled="pageLoading"
								class="blue darken-4 white--text"
							>
								Submit
							</v-btn>
						</td>
					</tr>
				</table>
			</div>
			<div v-if="errors.length">
				<v-alert class="errors" v-for="(error, index) in errors" :key="index">
					{{ error }}
				</v-alert>
			</div>
		</div>
	</v-app>
</template>

<style type="text/css" scoped>
.v-alert.v-sheet.errors {
	position: fixed;
	top: 10px;
	right: 10px;
	background-color: #ffe3e3;
	color: #b31919;
}
.quotation-approved h1 {
	color: #88b04b;
	font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
	font-weight: 900;
	font-size: 40px;
	margin-bottom: 10px;
}
.quotation-approved p {
	color: #404f5e;
	font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
	font-size: 20px;
	margin: 0;
}
.quotation-approved i {
	color: #9abc66;
	font-size: 100px;
	line-height: 200px;
	margin-left: -15px;
}
.quotation-approved .card {
	background: white;
	padding: 60px;
	border-radius: 4px;
	box-shadow: 0 2px 3px #c8d0d8;
	display: inline-block;
	margin: 0 auto;
}
.quotation-approved {
	text-align: center;
	padding: 40px 0;
	background: #ebf0f5;
}
.crossBtn {
	position: absolute;
	left: auto;
	right: 25px;
	top: 30px;
	width: 35px;
	cursor: pointer;
	background-color: rgb(192, 95, 95);
	padding: 6px;
	padding-top: 10px;
	border-radius: 30px;
}
.crossIcon {
	font-size: 20px !important;
	font-weight: 600 !important;
	color: #fff !important;
	margin: 0 !important;
	padding: 0 !important;
	line-height: 0 !important;
}
</style>

<script>
import SignaturePad from "signature_pad";
import { ApproveOrderItems } from "@/core/lib/cart.lib";
import { filter } from "lodash";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "retired-approve",
	props: {
		uuid: {
			type: String,
			default: null,
		},
		details: {
			type: Object,
			default: new Object(),
		},
	},
	watch: {
		details: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param && this.uuid) {
					this.init(param);
				}
			},
		},
	},
	data() {
		return {
			errors: [],
			retired: [],
			memberData: [],
			orderData: {},
			isSubmitted: false,
			requester_signature: null,
			pageLoading: false,
			approver_signature: null,
		};
	},
	methods: {
		clear_signature(type) {
			const signature_pad = this[type];
			if (signature_pad) {
				signature_pad.clear();
			}
		},
		initSignature() {
			const _this = this;
			let ccanvas = _this.$refs["cc_signature"];
			let ccanvasDiv = _this.$refs["signature_div"];
			ccanvas.setAttribute("width", 400);
			ccanvasDiv.setAttribute("style", "width:" + "400px");
			_this.requester_signature = new SignaturePad(ccanvas);

			let ccanvas1 = _this.$refs["cc_signature1"];
			let ccanvasDiv1 = _this.$refs["signature_div1"];
			ccanvas1.setAttribute("width", 400);
			ccanvasDiv1.setAttribute("style", "width:" + "400px");
			_this.approver_signature = new SignaturePad(ccanvas1);

			if (_this.orderData.admin_signature) {
				_this.requester_signature.fromDataURL(_this.orderData.admin_signature);
				_this.requester_signature.off();
			}
		},
		init(data) {
			const _this = this;
			_this.orderData = data;
			_this.memberData = data.member;
			_this.retired = filter(data.order_items, (row) => {
				return row.type == 4;
			});
		},
		approveRetired() {
			const _this = this;
			_this.errors = [];
			if (_this.approver_signature.isEmpty()) {
				_this.errors.push("Requestor Signatory is required.");
				return false;
			}
			if (_this.requester_signature.isEmpty()) {
				_this.errors.push("Approved by signature is required.");
				return false;
			}
			let approverSignatureUrl = _this.approver_signature.toDataURL();
			let requesterSignatureUrl = _this.requester_signature.toDataURL();
			this.pageLoading = true;
			ApproveOrderItems(_this.uuid, {
				type: "retired",
				approver_signature: approverSignatureUrl,
				requester_signature: requesterSignatureUrl,
			})
				.then((data) => {
					console.log(data);
					_this.isSubmitted = true;
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeTab() {
			window.close();
		},
		quantity(data) {
			if (data && data.retired && data.retired.quantity) {
				return data.retired.quantity;
			} else {
				return "-";
			}
		},
	},
	computed: {
		currentDate() {
			const today = new Date();
			return this.formatDate(today);
		},
	},
	mounted() {
		this.initSignature();
		if (!this.uuid) {
			this.goBack();
		}
	},
};
</script>
